import { Component, Inject, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { filter, Observable, of, Subscription, switchMap, tap } from 'rxjs';

import { AppStateService, ChemistryAccession } from '../../app-state.service';
import { AccessionsService } from './accessions.service';

import { AssayCardsComponent } from '../assay-cards/assay-cards.component';
import { AssayDetailsComponent } from '../assay/assay-details/assay-details.component';
import { BatchedAssaySelection } from '../../shared/interfaces/batchedAssaySelection.interface';
import { AssaysService } from '../assay/assays.service';
import {
  AuditService,
  Comment,
  KeyboardAction,
  KeyboardService,
  Lab,
  LabNotesComponent,
  LabNotesService,
  LabsService,
  Link,
  ModalContainerService,
  Workspace,
} from '@lims-common-ux/lux';
import { ActivatedRoute, GuardsCheckEnd, NavigationStart, Router } from '@angular/router';
import { WorkspaceAccessionService } from '../workspace-accession.service';
import { Accession } from '../../shared/interfaces/accession.interface';
import { Assay, AssayStatus } from '../../shared/interfaces/assay.interface';
import { WorkspaceQueueService } from '../workspace-queue.service';
import { Title } from '@angular/platform-browser';
import { AccessionHeaderValueService } from '../../lab/accession-header-value.service';

export class AccessionInformation {
  accession: ChemistryAccession;
  headerAccessionInfo: Accession;
  workspace: Workspace;
  assays: Assay[];
}

export class QueueInformation extends AccessionInformation {
  nextLink: Link;
  empty = false;
}

@Component({
  selector: 'app-accession',
  templateUrl: './accession.component.html',
  styleUrls: ['./accession.component.scss'],
})
export class AccessionComponent implements OnInit, OnDestroy {
  @ViewChild('assayCards', { static: false }) assayCards: AssayCardsComponent;
  @ViewChild('assayDetails', { static: false }) assayDetails: AssayDetailsComponent;
  @ViewChild('labNotes')
  labNotes: LabNotesComponent;

  @Input()
  pending = false;

  lab: Lab;

  private helpModaClosedSub: Subscription;
  workspace: Workspace;
  accession: ChemistryAccession;

  private _headerAccession: Accession;
  set headerAccession(accession: Accession) {
    this._headerAccession = accession;
    this.accessionHeaderValue.setValue(accession);
  }

  get headerAccession(): Accession {
    return this._headerAccession;
  }

  currentAssay: Assay;
  nextInQueueLink: Link = null;
  selectedBatchOperationAssays: Assay[] = [];

  constructor(
    public appStateService: AppStateService,
    private workspaceService: WorkspaceAccessionService,
    private workspaceQueueService: WorkspaceQueueService,
    private accessionService: AccessionsService,
    private assaysService: AssaysService,
    private keyboardService: KeyboardService,
    private route: ActivatedRoute,
    private router: Router,
    private auditService: AuditService,
    private modalService: ModalContainerService,
    private title: Title,
    private labService: LabsService,
    private accessionHeaderValue: AccessionHeaderValueService,
    private labNotesService: LabNotesService,
    @Inject('Window') private window: any
  ) {
    // any start to a routing event needs to remove the global header value
    this.router.events.pipe(filter((event) => event instanceof NavigationStart)).subscribe((event) => {
      if (event instanceof NavigationStart) {
        this.workspace = this.accession = this.currentAssay = this.nextInQueueLink = this.headerAccession = null;
      }
    });

    this.route.data.pipe().subscribe((data) => {
      if (data.accession) {
        const info = data.accession as AccessionInformation;
        this.setCommonResolveInfo(info);
        this.nextInQueueLink = null;
      } else if (data.queueInformation) {
        // in a queue
        const info = data.queueInformation as QueueInformation;
        this.setCommonResolveInfo(info);
        this.nextInQueueLink = info.nextLink;
      }
      if (this.accession?.assays?.length) {
        this.currentAssay = this.accession.assays[0];
        requestAnimationFrame(() => {
          this.focusFirstAssay();
        });
      }
    });
  }

  focusFirstAssayAction: KeyboardAction = {
    name: 'assay-first-focus',
    matchCallback: ($evt: KeyboardEvent) => {
      this.keyboardService.preventDefaultAndPropagation($evt);
      this.focusFirstAssay();
    },
  };

  acceptAssayAction: KeyboardAction = {
    name: 'accept-assay-result',
    eventMatch: { key: 'F4' },
    matchCallback: ($evt) => {
      this.keyboardService.preventDefaultAndPropagation($evt);
      this.handleAcceptResult(this.currentAssay.currentResult);
    },
  };

  acceptAllAssaysAction: KeyboardAction = {
    name: 'accept-all-assay-results',
    matchCallback: ($evt) => {
      this.keyboardService.preventDefaultAndPropagation($evt);
      this.triggerAcceptAllResults();
    },
  };

  repeatAssayAction: KeyboardAction = {
    name: 'repeat-assay',
    matchCallback: ($evt) => {
      this.keyboardService.preventDefaultAndPropagation($evt);

      if (!this.selectedBatchOperationAssays.length) {
        this.handleRepeat();
      } else {
        this.handleBatchRepeat();
      }
    },
  };

  noResultAssayAction: KeyboardAction = {
    name: 'no-result-assay',
    matchCallback: ($evt) => {
      this.keyboardService.preventDefaultAndPropagation($evt);

      if (!this.selectedBatchOperationAssays.length) {
        this.handleNoResult();
      } else {
        this.handleBatchNoResult();
      }
    },
  };

  deselectAllBatchedAssaysAction: KeyboardAction = {
    name: 'deselect-all-batched',
    matchCallback: ($evt) => {
      this.keyboardService.preventDefaultAndPropagation($evt);

      this.clearBatched();

      this.assayCards.assayCards.forEach((assayCard) => {
        if (assayCard.isSelected) {
          assayCard.focus();
        }
      });
    },
    removeOnMatch: true,
  };

  focusAssayCommentAction: KeyboardAction = {
    name: 'focus-assay-comment',
    matchCallback: ($evt: KeyboardEvent) => {
      this.focusResultComment($evt);
    },
  };

  nextInQueueAction = {
    name: 'next-in-queue',
    eventMatch: {
      key: 'ArrowRight',
      altKey: true,
      matcher: () => !this.modalService.openModal,
    },
    matchCallback: ($evt: KeyboardEvent) => {
      this.keyboardService.preventDefaultAndPropagation($evt);
      if (this.nextInQueueLink && !this.labNotes?.hasUnsavedLabNotes) {
        this.nextInQueue();
      }
    },
  } as KeyboardAction;

  private saveLabNoteAction = {
    name: 'save-assays',
    eventMatch: {
      key: 's',
      altKey: true,
      matcher: (event: KeyboardEvent) => !this.modalService.openModal,
    },
    matchCallback: ($evt) => {
      if (this.labNotes?.visible) {
        $evt.preventDefault();
        this.labNotes.addLabNote();
      }
    },
  } as KeyboardAction;

  ngOnInit() {
    this.keyboardService.addActions([
      this.acceptAssayAction,
      this.acceptAllAssaysAction,
      this.repeatAssayAction,
      this.noResultAssayAction,
      this.focusAssayCommentAction,
      this.nextInQueueAction,
      this.focusFirstAssayAction,
      this.saveLabNoteAction,
    ]);

    this.helpModaClosedSub = this.appStateService.helpModalClosed.subscribe(() => {
      this.focusFirstAssay();
    });

    this.router.events.subscribe((e) => {
      if (e instanceof GuardsCheckEnd && e.shouldActivate) {
        this.clearBatched();
      }
    });
    this.lab = this.labService.currentLab;
  }

  ngOnDestroy(): void {
    if (this.helpModaClosedSub) {
      this.helpModaClosedSub.unsubscribe();
    }
  }

  private setCommonResolveInfo(info: AccessionInformation) {
    this.accession = info.accession;
    this.workspace = info.workspace;
    // This is sort of fallback, as workspace component also sets the title. This is required though when we
    // view an accession that is not in the current lab, and we can't resolve the workspace through the normal
    // resolve functionality.
    let title = this.workspace.name;

    if (this.appStateService.env === 'uat' || this.appStateService.env === 'exp') {
      title += ` (${this.appStateService.env})`;
    }

    this.title.setTitle(title);

    setTimeout(() => {
      this.headerAccession = info.headerAccessionInfo;
    });
  }

  focusFirstAssay() {
    this.assayCards?.assayCards?.first?.focus();
  }

  handleResultEntryEscape() {
    this.assayCards.focusSelectedAssayCard();
  }

  nextInQueue() {
    // we clear accession information
    // since we dont change the route
    this.headerAccession = null;
    this.accession = null;
    this.currentAssay = null;
    this.appStateService.loading = true;
    this.clearBatched();
    if (this.labNotesService.labNotesOpen) {
      this.labNotes?.closeLabNotesModal();
    }

    this.workspaceQueueService
      .advanceQueue(this.nextInQueueLink, this.workspace)
      .pipe(
        switchMap((queueInfo) => {
          if (queueInfo) {
            this.headerAccession = queueInfo.accession;
            this.nextInQueueLink = queueInfo.next;
            return this.accessionService.loadChemistryAccession(queueInfo.accession, this.workspace).pipe(
              switchMap((workspaceAccession: ChemistryAccession) => {
                this.accession = workspaceAccession;
                return this.workspaceService.getAssays(this.accession).pipe(
                  tap((assays) => {
                    this.accession.assays = assays;
                    this.currentAssay = this.accession.assays[0];
                    this.auditService.fireAccessionLoaded(queueInfo.accession.id);
                  })
                );
              })
            );
          } else {
            return of(null);
          }
        })
      )
      .subscribe((acc) => {
        this.appStateService.loading = false;
      });
  }

  handleDeleteBatchedItem(assaySelection: BatchedAssaySelection) {
    if (!assaySelection.batched) {
      this.removeAssayFromBatchOperation(assaySelection.assay);
    }
  }

  handleRemoveAllBatchedItems() {
    this.clearBatched();

    this.assayCards.assayCards.forEach((assayCard) => {
      if (assayCard.assay === this.currentAssay) {
        assayCard.focus();
      }
    });
  }

  handleBatchNoResult() {
    if (!this.assayDetails.shouldDisableBatchedNoResult() && !this.pending) {
      this.appStateService.loading = true;
      this.assaysService.batchNoResult(this.accession, this.selectedBatchOperationAssays).subscribe({
        next: (updated) => {
          this.handleSuccessfulBatch(updated);
        },
        error: (err) => {
          this.handleFailedBatch(err);
        },
      });
    }
  }

  handleBatchRepeat() {
    if (!this.assayDetails.shouldDisableBatchedRepeat() && !this.pending) {
      this.appStateService.loading = true;
      this.assaysService.batchRepeat(this.accession, this.selectedBatchOperationAssays).subscribe({
        next: (updated) => {
          this.handleSuccessfulBatch(updated);
        },
        error: (err) => {
          this.handleFailedBatch(err);
        },
      });
    }
  }

  handleBatchComment(comment: Comment) {
    if (!this.pending) {
      this.appStateService.loading = true;
      this.assaysService.batchComment(this.accession, comment, this.selectedBatchOperationAssays).subscribe({
        next: (assays) => {
          setTimeout(() => {
            this.handleSuccessfulBatch(assays);
          }, 0);
        },
        error: (err) => {
          this.handleFailedBatch(err);
        },
      });
    }
  }

  handleAssayCardSelection(assay: Assay) {
    this.currentAssay = assay;
  }

  focusAssayDetails() {
    this.assayDetails.focusSelectedAssayEntryControl();
  }

  handleAssayUpdated(assays: Assay[]) {
    this.currentAssay = assays.find((up) => up.testCode === this.currentAssay?.testCode);
    this.accessionService.updateAssays(this.accession, assays);
  }

  handleBatchSelection(assaySelection: BatchedAssaySelection) {
    if (!assaySelection.batched) {
      this.addAssayToBatchOperation(assaySelection.assay);

      if (this.selectedBatchOperationAssays.length === 1) {
        this.keyboardService.addActions([this.deselectAllBatchedAssaysAction]);
      }
    } else {
      this.removeAssayFromBatchOperation(assaySelection.assay);
    }
  }

  handleSuccessfulBatch(updated: Assay[]) {
    this.accessionService.updateAssays(this.accession, updated);
    const newCurrent = updated.find((a) => a.testCode === this.currentAssay.testCode);
    if (newCurrent) {
      this.currentAssay = newCurrent;
    }
    this.clearBatched();
    this.appStateService.loading = false;
  }

  handleFailedBatch(err) {
    throw err;
  }

  focusResultComment($event) {
    $event.preventDefault();
    $event.stopImmediatePropagation();

    if (this.assayDetails && this.assayDetails.selectedAssay._links?.addComment) {
      setTimeout(() => {
        if (!this.assayDetails.cmpWrapper.nativeElement.contains(document.activeElement)) {
          this.assayDetails.focusResultComment();
        }
      });
    }
  }

  handleNoResult() {
    const selectedAssay = this.currentAssay;
    // SelectedAssay check here because if the user has moved to the 'workspace' view, nothing is selected,
    // but they could still try to use the shortcut to NoResult an assay.
    if (selectedAssay?._links?.markAsNoResult?.href && !this.assaysService.pending) {
      this.completeAssayUpdate(this.assaysService.setNoResult(this.currentAssay));
    }
  }

  triggerAcceptAllResults() {
    this.completeAssayUpdate(this.assaysService.acceptAllResults(this.accession), () => {
      if (this.assayDetails.selectedAssay.status !== AssayStatus.CANCELED) {
        this.assayDetails.resultComment.focusSearchInput();
      }
    });
  }

  handleAcceptResult(result) {
    if (result?._links?.acceptResult && !this.assaysService.pending) {
      this.completeAssayUpdate(this.assaysService.acceptResult(result));
    }
  }

  handleRepeat() {
    const selectedAssay = this.currentAssay;

    if (selectedAssay?._links?.requestRepeat && !this.assaysService.pending) {
      this.completeAssayUpdate(this.assaysService.repeat(this.currentAssay));
    }
  }

  private completeAssayUpdate(update: Observable<Assay[]>, onComplete: () => void = null) {
    this.appStateService.loading = true;
    update.subscribe({
      next: (res) => {
        this.appStateService.loading = false;
        if (res.length > 0) {
          // can have update calls that end up not updating anything. If nothing is updated, currentAssay doesn't need
          // to be updated either.
          this.currentAssay = res.find((up) => up.testCode === this.currentAssay.testCode);
          this.accessionService.updateAssays(this.accession, res);
        } else {
          requestAnimationFrame(() => {
            this.assayCards.focusSelectedAssayCard();
          });
        }
        if (onComplete) {
          onComplete();
        }
      },
      error: (error) => {
        throw error;
      },
    });
  }

  addAssayToBatchOperation(assay: Assay) {
    const alreadySelected = this.selectedBatchOperationAssays.filter((item) => {
      return item.testCode === assay.testCode;
    });
    if (!alreadySelected.length) {
      this.selectedBatchOperationAssays.push(assay);
    }
  }

  removeAssayFromBatchOperation(assay: Assay) {
    this.selectedBatchOperationAssays.forEach((item, i) => {
      if (item.testCode === assay.testCode) {
        this.selectedBatchOperationAssays.splice(i, 1);
      }
    });
  }

  clearBatched() {
    this.selectedBatchOperationAssays = [];
  }
}
